<template>
  <div class="login height_100 pad40 sizing">
    <el-row class="height_100">
      <el-col :span="24" class="height_100">
        <div class="login_bg_box height_100">
          <login-box></login-box>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import LoginBox from "./components/login_box";

export default {
  components: {
    LoginBox,
  },
};
</script>
<style lang="scss" scoped>
.login {
  background: url("../../assets/loginBg.jpg") no-repeat;
  background-size: 2200px 1080px;
  background-color: #333;
  min-height: 650px;
  min-width: 1241px;
  .login_bg_box {
    background: rgba(255, 255, 255, 0.3);
    min-height: 550px;
  }
}
</style>
